<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    if (localStorage.getItem("predicthealthUserInfo")) {
      let data = JSON.parse(localStorage.getItem("predicthealthUserInfo"));
      this.$store.state.loginUserInfo = data;
    }
    if (location.host.indexOf("admin.predicthealthen.com") !== -1) {
      this.$store.state.language = "en";
      document.title = "Report management system";
    } else {
      this.$store.state.language = "zh";
      document.title = "后台管理系统";
    }
    if (location.port || location.host.indexOf("test.") != -1) {
      this.$store.state.uploadImgUrl =
        "http://121.41.24.250:8119/component/oss/partETagFile";
      // this.$store.state.baseUrl = "https://api.predicthealth.cn/";
      this.$store.state.baseUrl = "https://47070f2j95.oicp.vip/";

    } else {
      this.$store.state.uploadImgUrl =
        "https://api.yijiankang365.com/component/oss/partETagFile";
      this.$store.state.baseUrl = "https://api.predicthealth.cn/";
    }
  },
  methods: {},
};
</script>
<style lang="less">
/* 隐藏滚动条 */
.templateCentreContent {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: auto;
}
.templateCentreContent::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
* {
  box-sizing: border-box;
}
.el-loading-mask.is-fullscreen {
  z-index: 9999 !important;
}
// form中上传图片的统一样式
.uploadFormItem {
  .el-form-item__content {
    display: flex;
    align-items: flex-end;
  }
  .uploadImg {
    max-width: 200px;
    display: block;
    cursor: pointer;
    margin-right: 20px;
  }
  .noImg {
    width: 160px;
    height: 200px;
    border: 1px dashed #ccc;
    border-radius: 10px;
    font-size: 50px;
    color: #ccc;
    line-height: 200px;
    text-align: center;
    margin-right: 10px;
  }
  // .uploadBtn {
  // }
}
/* 关闭按钮的大小设置 */
.el-dialog {
  .el-icon-close {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
    font-size: 18px;
  }
}
/* 表单的label加粗 */
.el-form-item__label {
  font-weight: 700;
}
/* 表格操作哪里不显示下面的线 */
.el-table__fixed-right::before,
.el-table__fixed::before {
  display: none;
}
/* 行高 */
.el-textarea__inner {
  line-height: 24px;
}
/* 小屏幕上 */
.el-drawer__body {
  overflow-y: auto;
}
/* 统一的线条颜色 */
@lineColor: #f0efee;
// @lineColor:rgb(243, 242, 241);
// @lineColor:red;
.line {
  border-bottom: 1px solid @lineColor !important;
}
.el-table--border,
.el-table--group {
  border-color: @lineColor !important;
}
.el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-color: @lineColor !important;
}
.el-table td,
.el-table th.is-leaf {
  border-color: @lineColor !important;
}
.del {
  color: #f56c6c;
}
.del:hover {
  opacity: 0.8;
}
html,
body,
p {
  margin: 0px;
}

a,
a:link,
a:visited,
a:active {
  color: #333;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
}
#app {
  font-family: "Microsoft YaHei", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.formPs {
  color: #999;
}
/* 返回按钮的统一样式 */
.previousPage {
  text-align: left;
  // width: 92px;
  padding-left: 30px;
  padding-right: 40px;
  height: 36px;
  line-height: 36px;
  color: #409eff;
  border: 1px solid #409eff;
  border-radius: 5px;
  font-size: 16px;
  background-image: url("./assets/previousPageIcon.png");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 65px center;
  cursor: pointer;
}
.previousPage:hover {
  opacity: 0.5;
}
/* 表格样式统一 居中 */
.el-table td,
.el-table th {
  text-align: center !important;
}
/* 表头设置背景色 */
.el-table th {
  background-color: #fafafa !important;
}
/* 表格操作栏的统一样式 */
.tableOperation {
  .E6A23C,
  .E6A23C:active,
  .E6A23C:focus,
  .E6A23C:visited {
    color: #e6a23c;
  }
  .F56C6C,
  .F56C6C:active,
  .F56C6C:focus,
  .F56C6C:visited {
    color: #f56c6c;
  }
  .E6A23C:hover {
    opacity: 0.8;
    color: #e6a23c;
  }
  .F56C6C:hover {
    opacity: 0.8;
    color: #f56c6c;
  }
  .operationLine {
    color: #f0efee;
    margin-left: 10px;
    margin-right: 10px;
  }
}
/* 查询条件统一样式 */
.spaceBetween {
  justify-content: space-between;
  > div:last-child {
    margin-left: 10px;
  }
}
.el-cascader {
  .el-input {
    width: 95% !important;
  }
  input {
    width: 100% !important;
  }
}
.el-cascader.width100 {
  .el-input {
    width: 100% !important;
  }
}
.query,
.spaceBetween {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .el-select,
  .el-input,
  .el-picker,
  .el-date-editor,
  .el-radio-group {
    margin-left: 10px;
    width: 150px;
  }
  .el-button,
  .el-select,
  .el-input,
  .el-picker,
  .el-date-editor,
  .el-radio-group {
    margin-top: 10px;
  }
  .el-select {
    .el-input {
      margin-left: 0;
      margin-top: 0;
      width: 100%;
    }
  }
}
.el-range-separator {
  width: 10% !important;
}
/* 整体容器框的样式 */
.content-box {
  height: ~"calc(100vh - 54px)";
  overflow: auto;
}
.centreContent1 {
  position: absolute;
  left: 200px;
  width: ~"calc(100vw - 200px)";
  height: ~"calc(100vh - 54px)";
  overflow: auto;
  background-color: #f4f4f4;
}
.centreContent2 {
  margin-left: 65px;
  transition: left 0.3s ease-in-out;
  width: ~"calc(100vw - 65px)";
}
.templateClass {
  .templateTopTag {
    height: 40px;
    line-height: 40px;
    padding: 0px 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
  }
  .templateCentreContent {
    height: ~"calc(100vh - 100px)";
    padding: 20px;
    padding-top: 10px;
    box-sizing: border-box;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0);
  }
}
.floatRight {
  float: right;
  margin: 10px 0px;
}
.ml10 {
  margin-left: 10px !important;
}
.width100 {
  width: 100%;
}
.el-card {
  // 统一修改为无边框及圆角4px改为8px
  border: none !important;
  border-radius: 8px !important;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1) !important;
  .el-card__header {
    padding: 20px;
    padding-top: 10px !important;
    padding-left: 10px !important;
  }
  .el-card__body {
    padding: 20px;
  }
}
/* 上传图片通用样式修改 */
.width50height50 {
  .avatar-uploader-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}
.width200height200 {
  .avatar-uploader-icon {
    font-size: 30px;
    width: 200px;
    height: 200px;
    line-height: 200px;
  }
  img {
    max-width: 100%;
    max-height: 200px;
  }
}
.avatar-uploader-icon {
  color: #8c939d;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-icon:hover {
  border-color: #409eff;
  color: #409eff;
}
</style>
